import { Typography, } from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useEffect } from 'react';
import shareTrip_banner from '../../assets/images/ShareTrip_banner.png';
import circle from "../../assets/images/circle4.png";
import Footer from "../../components/Footer";
import HeaderOthers from "../../components/HeaderOthers";


const useStyle = makeStyles((theme) => ({
    root: {
        margin: "60px 0px",
        [theme.breakpoints.down("sm")]: {
            margin: "0px",
        },
    },
    title: {
        fontWeight: "500 !important",
        marginBottom: "20px !important",
        marginTop: "24px !important",
    },
    subTitle: {
        fontWeight: "500 !important",
        marginBottom: "20px !important",
        marginTop: "0px !important",
    },
    content: {
        fontSize: "1rem",
        marginBottom: "20px !important",
    },

    img: {
        maxWidth: "100%",
    },
    footer: {
        marginTop: "-80px",
    },
    card: {
        padding: "24px",
        boxShadow: "1px 1px 35px 1px #eee",
        borderRadius: "10px",
        marginTop: "32px",
        [theme.breakpoints.down("sm")]: {
            padding: "7px",
        },
    },
    ul: {
        margin: 0,
        paddingLeft: "0px !important",
    },
    li: {
        marginBottom: "16px",
        listStyle: "none",
        display: "flex",
        alignItems: "flex-start",
    },
    circle: {
        marginRight: "10px",
        display: "flex",
        position: "relative",
        top: 5,
    },
}));

const ShareTripTwo = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const classes = useStyle();
    return (
        <>
            <HeaderOthers title="Vouchers for ST Pay Users" category="Offers" />
            <div className={classes.root}>
                <Container maxWidth="lg">
                    <Grid container spacing={2}>
                        <Grid item md={12} style={{ width: "100%" }}>
                            <Box className={classes.card}>
                                <img src={shareTrip_banner} alt="" className={classes.img} />
                                <Typography variant="h3" className={classes.title}>
                                    ShareTrip vouchers for ST Pay users
                                </Typography>
                                <p>
                                    It’s time to save more on your travel plans. Purchase amazing vouchers from ST Pay at special prices and
                                    enjoy upto 10% discount on services availed from ShareTrip.
                                </p>
                                <p>
                                    Vouchers available for flights (with 5% discount), hotels (with 5% discount) and holiday (with 10% discount).
                                    Vouchers validity is 6 months after your purchase or voucher activation.
                                </p>
                                <ul>
                                    <li>Holiday vouchers of BDT 10,000 available for BDT 9,000</li>
                                    <li>Flight Voucher of BDT 10,000 available at BDT 9,500</li>
                                    <li>Hotels vouchers of BDT 5,000 available for BDT 4,750</li>
                                </ul>
                            </Box>
                            <Box className={classes.card}>
                                <Typography variant="h4" className={classes.subTitle}>
                                    Details
                                </Typography>

                                <ul className={classes.ul}>
                                    <li className={classes.li}>
                                        <img src={circle} alt="" className={classes.circle} />
                                        <span>
                                            The voucher code will be activated against the customer phone number and can be found in the app under the vouchers section.
                                        </span>
                                    </li>

                                    <li className={classes.li}>
                                        <img src={circle} alt="" className={classes.circle} />
                                        <span>
                                            The voucher will be activated as soon as the payment has been made to purchase the voucher
                                        </span>
                                    </li>

                                    <li className={classes.li}>
                                        <img src={circle} alt="" className={classes.circle} />
                                        <span>
                                            Customers will be allowed to purchase regular price services (Fresh merchandise) using the voucher. Already discounted services at ShareTrip cannot be purchased with the vouchers.
                                        </span>
                                    </li>
                                </ul>

                                <ul className={classes.ul}>
                                    <li className={classes.li}>
                                        <img src={circle} alt="" className={classes.circle} />
                                        <span>
                                            These vouchers can only be availed at ShareTrip’s office.
                                        </span>
                                    </li>

                                    <li className={classes.li}>
                                        <img src={circle} alt="" className={classes.circle} />
                                        <span>
                                            To redeem the voucher customer needs to provide the voucher code number to the ShareTrip’s office.
                                        </span>
                                    </li>

                                    <li className={classes.li}>
                                        <img src={circle} alt="" className={classes.circle} />
                                        <span>
                                            Customer has to buy a product equal to or more than the value of his voucher.
                                        </span>
                                    </li>

                                    <li className={classes.li}>
                                        <img src={circle} alt="" className={classes.circle} />
                                        <span>
                                            Customers can purchase multiple vouchers under one account.
                                        </span>
                                    </li>

                                    <li className={classes.li}>
                                        <img src={circle} alt="" className={classes.circle} />
                                        <span>
                                            One customer can use single voucher or multiple vouchers in a single invoice or multiple invoices.
                                        </span>
                                    </li>

                                    <li className={classes.li}>
                                        <img src={circle} alt="" className={classes.circle} />
                                        <span>
                                            Customer must use the entire value of the voucher in a single transaction. If the total payable value exceeds the voucher value, an extra amount can be paid through ST Pay, cash or any other method available at the physical outlet.
                                        </span>
                                    </li>

                                    <li className={classes.li}>
                                        <img src={circle} alt="" className={classes.circle} />
                                        <span>
                                            Customers can only redeem the vouchers depending on the availability of the services.
                                        </span>
                                    </li>
                                    <li className={classes.li}>
                                        <img src={circle} alt="" className={classes.circle} />
                                        <span>
                                            The vouchers are non-refundable, non-exchangeable and non-transferrable.
                                        </span>
                                    </li>
                                    <li className={classes.li}>
                                        <img src={circle} alt="" className={classes.circle} />
                                        <span>
                                            The vouchers cannot be exchanged with cash.
                                        </span>
                                    </li>
                                    <li className={classes.li}>
                                        <img src={circle} alt="" className={classes.circle} />
                                        <span>
                                            ShareTrip will take care of customer claims regarding any service issues.
                                        </span>
                                    </li>
                                </ul>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div className={classes.footer}>
                <Footer />
            </div>
        </>
    );
};

export default ShareTripTwo;