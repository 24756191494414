import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import FooterBangla from "../../components/componentsBangla/FooterBangla";
import HeaderOthersBangla from "../../components/componentsBangla/HeaderOthersBangla";

const useStyle = makeStyles((theme) => ({
  root: {
    margin: "60px 0px",
  },
  title: {
    fontSize: "1.5rem !important",
    fontWeight: "500 !important",
    marginBottom: "20px !important",
    textAlign: "center"
  },
  content: {
    fontSize: "1rem",
    marginBottom: "20px !important",
  },
  subtitle: {
    fontSize: "1rem",
    marginBottom: "20px !important",
    fontWeight: "500 !important",
  },

  icon: {
    marginRight: 10,
    position: "relative",
    top: 5,
  },
  select: {
    marginBottom: 30,
  },
  footer: {
    marginTop: "-80px",
  },
}));

const PrivacyPolicyBangla = () => {
  const classes = useStyle();

  useEffect(() => {
    window.scrollTo(0, -1);
  }, []);

  return (
    <>
      <HeaderOthersBangla title="Privacy Policy" />
      <div className={classes.root}>
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            <Grid item md={12}>

              <Typography className={classes.title}>গোপনীয়তা পলিসি - এসটি পে</Typography>

              <p>এই গোপনীয়তা নীতি এবং শর্তাবলী আমরা অনলাইনে আমাদের সম্মানিত গ্রাহকদের "ব্যক্তিগত ডেটা" কীভাবে সংগ্রহ, প্রক্রিয়া এবং ব্যবহার করি তার একটি বিশদ ব্যাখ্যা দেওয়ার জন্য তৈরি করা হয়েছে। এটি সমস্ত এসটি পে গ্রাহক এবং ব্যবহারকারীদের জন্য (সাধারণত "ব্যবহারকারী" হিসাবে উল্লেখ করা হয়) এবং কোম্পানির সমস্ত পণ্য ও পরিষেবার ক্ষেত্রে প্রযোজ্য। আমরা কীভাবে আমাদের অ্যাপের সাথে সম্মতিতে আপনার ব্যক্তিগত ডেটা সংগ্রহ, সুরক্ষা বা অন্যথায় পরিচালনা করবেন তার স্পষ্ট ব্যাখ্যার জন্য দয়া করে আমাদের গোপনীয়তা বিবৃতিটি ভালোভাবে পড়ুন।</p>

              <h4>সংক্ষিপ্ত বিবরন:</h4>
              <p>যে কোনও কারণে আমাদের ওয়েবসাইট, মোবাইল অ্যাপ্লিকেশন, ইলেকট্রনিক যোগাযোগ, এবং/অথবা অন্যান্য পরিষেবাগুলি (প্ল্যাটফর্ম) এই গোপনীয়তা নীতির শর্তাবলীর সাপেক্ষে ব্যবহার হয় । প্ল্যাটফর্মটি পরিচালনা করতে এবং জালিয়াতি, মানি লন্ডারিং এবং সন্ত্রাসবাদে অর্থায়ন এর ঝুঁকি কমাতে গ্রাহকের কাছ থেকে নির্দিষ্ট ব্যক্তিগত তথ্য চাইতে পারে, যেমন জাতীয় পরিচয়পত্র নম্বর, পাসপোর্ট নম্বর, ড্রাইভিং লাইসেন্স নম্বর, ট্যাক্স শনাক্তকরণ নম্বর (টিআইএন) এবং/অথবা ব্যাঙ্ক অ্যাকাউন্টের তথ্য। এই গোপনীয়তা নীতির শর্তাবলী অনুসারে আমরা অত্যন্ত যত্ন সহকারে আপনার তথ্যের প্রক্রিয়াকরণ পরিচালনা করি। যেকোনো গোপনীয় তথ্য, ব্যক্তিগতভাবে শনাক্তযোগ্য তথ্য, বা যেকোনো প্ল্যাটফর্ম ব্যবহারকারীর সাথে সম্পর্কিত অন্যান্য তথ্যকে এই গোপনীয়তা নীতিতে "তথ্য" হিসাবে উল্লেখ করা হয়েছে।</p>
              <p>আপনার সুস্পষ্ট অনুমতি ব্যতীত আপনাদের তথ্য তৃতীয় পক্ষের কাছে তাদের বিপণনের প্রয়োজনের জন্য ভাড়া বা বিক্রি করব না। আপনার অনুরোধ করা পরিষেবা প্রদানের জন্য যদি আমাদেরকে আপনার তথ্য তৃতীয় পক্ষের কাছে প্রকাশ করতে হয়, আমরা এই গোপনীয়তা নীতিতে বর্ণিত বিধিনিষেধের অধীনে কঠোরভাবে তা করব। গোপনীয়তা নীতির বিবৃতি পড়বেন যখন আপনি এই প্ল্যাটফর্মের মাধ্যমে কোনো তৃতীয় পক্ষের ওয়েবসাইটগুলির অ্যাক্সেস করেন বা আপনি প্ল্যাটফর্ম অ্যাক্সেস করতে ব্যবহার করেন। এসটি পে কোনো তৃতীয় পক্ষের গোপনীয়তা অনুশীলনের জন্য দায়বদ্ধ হবে না।  </p>

              <h4>তথ্য:</h4>
              <p>একটি এসটি পে অ্যাকাউন্ট তৈরি করার সময় বা এসটি পে পরিষেবা ব্যবহার করার সময় আপনাকে অবশ্যই আপনার নাম, ফোন নম্বর, ইমেল ঠিকানা, জাতীয় পরিচয়পত্র, ড্রাইভিং লাইসেন্স নম্বর, পাসপোর্ট নম্বর, জন্ম নিবন্ধন নম্বর এবং অন্যান্য প্রয়োজনীয় শনাক্তকরণ বিবরণ লিখতে হবে। </p>
              <p>এই ডেটা আপনার ডিভাইসে সংরক্ষণ করা হবে এবং কোনভাবেই সংগ্রহ, বিক্রি, লেনদেন বা অন্য কোনো পক্ষের কাছে স্থানান্তর করা হবে না। আমাদের মূল উদ্দেশ্য হল আপনার জনসংখ্যা, চাহিদা এবং আগ্রহের উপর অভ্যন্তরীণ গবেষণা করা যাতে আপনাকে একটি নিরাপদ, কার্যকরী, সহজ-সরল পরিষেবা প্রদান করা যায় এবং আপনার স্বার্থ রক্ষা করা যায়। আমরা আপনার তথ্য রক্ষা করার জন্য যথাযথ পদক্ষেপ নিয়ে থাকি। </p>
              <p>সফ্টওয়্যারটি তৃতীয় পক্ষের পরিষেবাগুলি ব্যবহার করে যা ডাটা সংগ্রহ করতে পারে যা ব্যাক্তিকে সনাক্ত করতে ব্যবহার করা যেতে পারে। Google Play দ্বারা তৃতীয় পক্ষের পরিষেবা প্রদানকারীদের অ্যাপের গোপনীয়তা নীতির সাথে লিংক করুন ৷ </p>

              <h4>ডিভাইস শনাক্তকরণ:</h4>
              <p>এসটি পে তার প্ল্যাটফর্ম, পরিষেবা, বিষয়বস্তু এবং বিজ্ঞাপনের সাথে আপনার মিথস্ক্রিয়া থেকে ডেটা সংগ্রহ করে, যার মধ্যে রয়েছে ডিভাইস আইডি, ডিভাইসের ধরন, হার্ডওয়্যার মডেল, অপারেটিং সিস্টেম এবং সংস্করণ, অনন্য ডিভাইস শনাক্তকারী, অবস্থান, ভূ-অবস্থান সহ ডেটা, কম্পিউটার এবং সংযোগের তথ্য, পৃষ্ঠা দর্শনের পরিসংখ্যান, সাইটে এবং থেকে ট্রাফিক, বিজ্ঞাপন ডেটা, IP ঠিকানা, এবং কুকিজ এবং অন্যান্য ট্র্যাকিং প্রযুক্তি দ্বারা সংগৃহীত ডেটা। </p>

              <h4>লেনদেনের বিবরণ:</h4>
              <p>আপনার বাণিজ্যিক এবং/অথবা ব্যক্তিগত লেনদেনের তথ্য প্রযোজ্য আইনের অধীনে মানি লন্ডারিং এবং সন্ত্রাসবিরোধী দায়বদ্ধতা মেনে চলার উদ্দেশ্যে রেকর্ড করা হবে এবং মানি লন্ডারিং প্রতিরোধ আইন ২০১২ এবং সন্ত্রাসবিরোধী আইন ২০০৯-তেই শুধু সীমাবদ্ধ নয় বরং গণপ্রজাতন্ত্রী বাংলাদেশ সরকারের অন্য কোনো নিয়ম ও প্রবিধান-এর ক্ষেত্রেও প্রযোজ্য।
                এসটি পে এবং অন্যান্য প্রাসঙ্গিক সরকারী কর্তৃপক্ষ আপনার, আপনার ব্যবসা, এর পরিচালকগণ, শেয়ারহোল্ডার, অংশীদার, কর্মকর্তা, কর্মচারী এবং আপনার সম্পর্কে তথ্য সংগ্রহ করে আপনার কোম্পানির একটি পটভূমি পরীক্ষা পরিচালনা করতে পারে; প্রযোজ্য ক্ষেত্রে, যদি আপনি এসটি পে পরিষেবার মাধ্যমে অস্বাভাবিকভাবে বড় পরিমাণে লেনদেন করেন বা সন্দেহজনক কোনো লেনদেনে জড়িত থাকার সন্দেহ হয়।
                এসটি পে তার নিজস্ব বিবেচনার ভিত্তিতে পর্যায়ক্রমে ভোক্তা এবং/অথবা ব্যবসার তথ্য অ্যাক্সেস এবং বিশ্লেষণ করার অধিকার বজায় রাখে এবং এই ব্যাকগ্রাউন্ড চেক এবং পর্যালোচনাগুলি থেকে সংগ্রহ করা ডেটার উপর ভিত্তি করে একটি অ্যাকাউন্ট বন্ধ করার ক্ষমতা রাখে ।
              </p>

              <h4>এসএমএস পড়ার অনুমতি:</h4>
              <p>আমাদের অ্যাপের নিরাপত্তা বাড়ানোর জন্য আমাদের চলমান প্রচেষ্টার অংশ হিসেবে, আমরা আপনার ডিভাইসে এসএমএস বার্তা অ্যাক্সেস করার অনুমতির অনুরোধ করতে পারি। এই অনুমতিটি প্রাথমিকভাবে 6 সংখ্যার OTP (ওয়ান-টাইম-পাসওয়ার্ড) SMS স্বয়ংক্রিয়-পূর্ণ বৈশিষ্ট্যের জন্য প্রয়োজন, যা আপনার অ্যাকাউন্ট এবং ডিভাইসের 2-step যাচাইকরণের মাধ্যমে আপনার আর্থিক লেনদেনগুলিকে সুরক্ষিত রাখতে গুরুত্বপূর্ণ ভূমিকা পালন করে।</p>

              <h4>লগ ডেটা:</h4>
              <p>আপনি যখন এসটি পে পরিষেবা ব্যবহার করেন এবং অ্যাপে কোনও সমস্যা হয় তখন আমরা লগ ডেটা সংগ্রহ করি যা আপনার ফোনে তৃতীয় পক্ষের তথ্য হিসাবে পরিচিত। আপনার ডিভাইসের ইন্টারনেট প্রোটোকল ("IP") ঠিকানা, নাম, অপারেটিং সিস্টেম সংস্করণ, আমাদের পরিষেবা ব্যবহার করার সময় অ্যাপের কনফিগারেশন, আপনার পরিষেবাটি ব্যবহারের সময় এবং তারিখ এবং অন্যান্য পরিসংখ্যান এই লগ ডেটাতে অন্তর্ভুক্ত করা হয়৷</p>

              <h4>ওয়েবসাইট ট্রাফিক এবং ওয়েব ক্যাচ:</h4>
              <p>নির্বিঘ্ন ও কার্যকর এসটি পে পরিষেবা বজায় রাখার উদ্দেশ্যে আপনার আইপি ঠিকানা, ব্রাউজার তথ্য, এবং প্ল্যাটফর্ম অ্যাক্সেস করার জন্য সময়-সম্পর্কিত তথ্য আমাদের সার্ভারে সংরক্ষিত করা হবে। আমরা সেই ওয়েবসাইটগুলির ওয়েব ঠিকানাগুলিও পেতে পারি যেগুলি আপনাকে প্ল্যাটফর্মে বা প্ল্যাটফর্ম থেকে রাউট করেছে৷ প্ল্যাটফর্মটিকে আরও ব্যবহারকারী-বান্ধব করার জন্য আপনার কম্পিউটার বা ডিভাইসে সেশন কুকিজ, ওয়েব কুকিজ, পিক্সেল ট্যাগ, ওয়েব বীকন এবং অন্যান্য অনুরূপ প্রযুক্তিগুলি ছোট টেক্সট বা ছবি ফাইল সংরক্ষণ করতে ব্যবহার করা হবে৷  </p>

              <h4>ব্যক্তিগত তথ্য প্রকাশ:  </h4>
              <p>আইনি বাধ্যবাধকতা মেনে চলার জন্য এবং আমাদের নিয়ম ধরে রাখতে, ঠিকানা দাবি করে যে কোনো তালিকা বা অন্যান্য বিষয়বস্তু তৃতীয় পক্ষের অধিকার লঙ্ঘন করে বা অন্যদের অধিকার ও সম্পত্তি বা নিরাপত্তা রক্ষা করে আমাদের ব্যক্তিগত তথ্য প্রকাশ করতে হতে পারে। দেশের প্রযোজ্য আইন ও প্রবিধান অনুযায়ী এই ধরনের তথ্য জনসমক্ষে প্রকাশ করা হবে। আমরা নিম্নলিখিতকারণ গুলির জন্য আপনার ব্যক্তিগত তথ্যও প্রকাশ করতে পারি: </p> <br />
              <p>
                ১.	একটি যাচাইকৃত অনুরোধের প্রতিক্রিয়া হিসাবে আইন প্রয়োগকারী বা অন্যান্য সরকারী কর্মকর্তাদের সম্পর্কিত একটি ফৌজদারি তদন্ত বা অভিযুক্ত বেআইনি কার্যকলাপ। এই ধরনের ক্ষেত্রে, আমরা তদন্ত সম্পর্কে তথ্য প্রকাশ করব। <br />
                ২.	প্রাসঙ্গিক সদস্য/অংশীদার/বিক্রেতা/পেমেন্ট গেটওয়ে/ব্যাঙ্ক/ফাইনান্সিয়াল ইনস্টিটিউটগুলি যৌথ বিষয়বস্তু এবং পরিষেবাগুলি (যেমন নিবন্ধন, লেনদেন, তহবিল সংগ্রহ এবং গ্রাহক সহায়তা) প্রদানের জন্য, সম্ভাব্য অবৈধ কার্যকলাপ এবং নীতি লঙ্ঘন সনাক্তকরণ এবং প্রতিরোধে সহায়তা করার জন্য এবং তাদের পণ্য, পরিষেবা এবং যোগাযোগ সংক্রান্ত সিদ্ধান্তের জন্য একটি সম্পদ হিসাবে পরিবেশন করা হবে। <br />
                ৩.	পরিষেবাগুলির জন্য ঠিকাদার যেগুলো আমাদের ব্যবসায়িক প্রক্রিয়াগুলিকে সমর্থন করে (যেমন- জালিয়াতি তদন্ত, বিল সংগ্রহ, অনুমোদিত এবং পুরষ্কার প্রোগ্রাম এবং কো-ব্র্যান্ডেড ক্রেডিট কার্ড)৷ <br />
                ৪.	আপনি বিশেষভাবে অনুরোধ করেছেন এমন অন্যান্য পক্ষগুলিকে আমরা আপনার তথ্য প্রদান করি (অথবা যাদের সম্পর্কে আপনি অন্যথায় স্পষ্টভাবে অবহিত হন এবং একটি নির্দিষ্ট পরিষেবা ব্যবহার করার সময় সম্মত হন)। <br />
                ৫.	জালিয়াতি, বুদ্ধিবৃত্তিক সম্পত্তি লঙ্ঘন, জলদস্যুতা বা অন্যান্য বেআইনি কার্যকলাপের তদন্তের সাথে একত্রে ও আমরা আমাদের নিজস্ব বিবেচনার ভিত্তিতে, এসটি পে এবং অন্য কোন সংস্থার মধ্যে গোপনীয়তা নীতির জন্য এটি প্রয়োজনীয় বা উপযুক্ত বলে মনে করতে পারি। এই ধরনের পরিস্থিতিতে, আমরা আপনার প্রয়োজনীয় তথ্য প্রদান করব। <br />
                ৬.	অন্যান্য ব্যবসা, আমরা কি তাদের সাথে একত্রিত হতে চাই বা তাদের দ্বারা কেনা হবে। এই ধরনের সংমিশ্রণ ঘটলে, আমরা দাবি করব যে ফলস্বরূপ ব্যবসা আপনার ব্যক্তিগত ডাটা সংক্রান্ত এই গোপনীয়তা বিবৃতিটি মেনে চলে। যদি আপনার ব্যক্তিগত তথ্য এই নীতির পরিপন্থী এমনভাবে ব্যবহার করা হয় তবে আপনাকে আগে থেকে অবহিত করা হবে।) <br />
                ৭.	পূর্বোক্ত বিষয়গুলিকে সীমাবদ্ধ না করে, আমরা আপনার ব্যক্তিগত তথ্য আইন প্রয়োগকারী সংস্থা, অন্যান্য সরকারী আধিকারিকদের, বা অন্যান্য তৃতীয় পক্ষের কাছে সাবপোনা, আদালতের আদেশ, বা যথেষ্ট অনুরূপ আইনি প্রক্রিয়া ছাড়াই প্রকাশ করব না। যখন আমরা বিশ্বাস করি যে, আসন্ন শারীরিক ক্ষতি প্রতিরোধে বা আর্থিক ক্ষতি রোধ করতে বা গুরুতর ক্ষতির সম্ভাবনা রয়েছে এমন পরিস্থিতির প্রতিকারের জন্য এই তথ্যের প্রয়োজন।
              </p>

              <h4>আপনার তথ্য আপডেট করা এবং অ্যাক্সেস করা:</h4>
              <p>আপনার অ্যাকাউন্টে প্রোফাইল সেটিংসের মাধ্যমে, আপনি আপনার তথ্য আপডেট করতে পারেন। আপনি আপনার সম্পর্কে আমাদের কাছে থাকা যেকোনো ব্যক্তিগত তথ্য আপডেট করার জন্য বলতে পারেন যা আপনি ভুল, অসম্পূর্ণ বা ভুল বলে মনে করেন।</p>
              <p>আমাদের কাছে থাকা আপনার যেকোনো ব্যক্তিগত তথ্য অ্যাক্সেসের জন্য আপনি যেকোনো সময় আমাদের সাথে যোগাযোগ করতে পারেন। অ্যাডমিনিস্ট্রেটিভ এবং অন্যান্য সম্পর্কিত চার্জ আপনার পেমেন্ট থেকে ফি হিসাবে কাটা হতে পারে।</p>

              <h4>লেনদেনের সীমাবদ্ধতা</h4>
              <p>ব্যবহারকারীর ব্যক্তিগত তথ্য ও এই প্ল্যাটফর্মের ব্যবহার যা অন্য কারো সাথে শেয়ার করা যাবে না। আপনার পাসওয়ার্ড(গুলি) সুরক্ষিত রাখা আপনার দায়িত্ব। ইন্টারনেট প্রায়শই একটি নিরাপদ পরিবেশ থাকে আবার সেখানে মাঝে মাঝে পরিষেবা বিভ্রাট বা অন্যান্য অপ্রত্যাশিত পরিস্থিতিও হতে পারে। তাই ইন্টারনেটের মাধ্যমে তথ্য যোগাযোগের সময় হারিয়ে যাওয়া কোনো ডাটার জন্য এসটি পে দায়বদ্ধ হবে না।</p>

              <h4>সংগৃহীত তথ্যের ব্যবহার</h4>
              <p>
                •	এই প্ল্যাটফর্মের সাথে একটি নির্বিঘ্ন, নিরাপদ, কার্যকরী এবং ব্যক্তিগতকৃত অভিজ্ঞতা দেওয়ার জন্য আপনার ব্যক্তিগত তথ্য সংগ্রহ, প্রক্রিয়াকরণ এবং বাংলাদেশের সার্ভারে রাখা হবে। <br />
                •	গ্রাহকের অনুরোধের ভিত্তিতে এসটি পে পরিষেবাগুলির প্রক্রিয়াকরণ শেষ করার জন্য বা কোন সন্দেহজনক বা বেআইনি লেনদেন সম্পর্কে অনুসন্ধান, অভিযোগ বা দাবির প্রতিক্রিয়ার মতো গুরুত্বপূর্ণ তথ্য বা কার্যকলাপ নিশ্চিত করতে, আমরা আপনার দেওয়া তথ্য ব্যবহার করে আপনার সাথে যোগাযোগ করব।<br />
                •	আপনি যখন একটি এসটি পে পরিষেবার ব্যবহারের অনুরোধ পাঠান, তখন আপনার নাম, ফোন নম্বর, ইমেল ঠিকানা, লেনদেনের পরিমাণ, লেনদেনের সময় ইত্যাদি সহ আপনার প্রাথমিক তথ্য অন্যান্য এসটি পে পরিষেবার সাথে ভাগ করা হতে পারে।<br />
                •	আপনার কাঙ্খিত এসটি পে পরিষেবাগুলি সম্পূর্ণ করার জন্য, বেশ কয়েকটি তৃতীয় পক্ষের যেমন নিয়ন্ত্রক সংস্থা, মোবাইল অপারেটর এবং অন্যান্য পরিষেবা প্রদানকারীদের কিছু তথ্য অ্যাক্সেসের প্রয়োজন হতে পারে। শুধুমাত্র যেখানে আইনি বাধ্যবাধকতা মেনে চলা, আমাদের নিয়মগুলি মেনে চলা, একটি তালিকা বা অন্যান্য বিষয়বস্তু এসটি পে বা অন্যদের অধিকার লঙ্ঘন করে এমন অভিযোগের সমাধান করা বা কারও অধিকার, সম্পত্তি বা নিরাপত্তা রক্ষা করা প্রয়োজন হলে আমরা তৃতীয় পক্ষের কাছে প্রয়োজনীয় তথ্য সরবরাহ করব। দেশের প্রযোজ্য নিয়ম ও প্রবিধান অনুযায়ী এই তথ্য জানাতে হবে। যেমনটি আগে বলা হয়েছে, আপনার স্পষ্ট অনুমতি ছাড়া, আমরা আপনার ব্যক্তিগত তথ্য তৃতীয় পক্ষের কাছে তাদের বিপণনের কারণে প্রকাশ করি না।<br />
                •	আমাদের রেকর্ড সঠিক কিনা তা নিশ্চিত করতে অডিটররা আপনার ডাটা এবং লেনদেনের ইতিহাস অ্যাক্সেস করতে পারে। তবে অন্য কোনো উদ্দেশ্যে আপনার ব্যক্তিগতভাবে শনাক্তযোগ্য তথ্যের ব্যবহার অডিটরদের দ্বারা অনুমোদিত হবে না।
              </p>

              <h4>সেবা প্রদানকারী:</h4>
              <p>একটি "কুকি" হল একটি সামান্য ডাটা পয়েন্ট যা একটি ওয়েব সার্ভার সংরক্ষণ করে। আমাদের পরিষেবার সুবিধার্থে, আমাদের পক্ষ থেকে পরিষেবা সরবরাহ করতে, পরিষেবা-সম্পর্কিত পরিষেবাগুলি পরিচালনা করতে, বা আমাদের পরিষেবা কীভাবে ব্যবহার করা হয় তা বিশ্লেষণ করতে সাহায্য করার জন্য আমরা তৃতীয় পক্ষের ব্যবসা এবং লোকদের নিয়োগ করতে পারি। আপনার ব্যক্তিগত তথ্য এই পক্ষগুলির কাছে অ্যাক্সেসযোগ্য। আমাদের পক্ষ থেকে  অর্পিত কাজগুলি সম্পাদন করতে তারা এটি ব্যবহার করবে৷ তবে, তাদের অবশ্যই অন্য কোনো কারণে তথ্য শেয়ার করা বা ব্যবহার করা থেকে বিরত থাকতে হবে।</p>

              <h4>নিরাপত্তা</h4>
              <p>আপনার অ্যাকাউন্টের চাবিকাঠি হল আপনার পাসওয়ার্ড। এসটি পে প্ল্যাটফর্ম দ্বারা নির্দিষ্ট করা স্বতন্ত্র সংখ্যা, অক্ষর এবং বিশেষ অক্ষর ব্যবহার করুন এবং আপনার অ্যাকাউন্টের পাসওয়ার্ড গোপন রাখুন। মনে রাখবেন যে আপনি যদি আপনার পাসওয়ার্ড বা অন্যান্য ব্যক্তিগত তথ্য অন্যদের সাথে শেয়ার করেন তবে আপনার অ্যাকাউন্টের অধীনে করা সমস্ত কর্মের জন্য আপনি দায়ী থাকবেন। আপনি যদি আপনার পাসওয়ার্ড হারিয়ে ফেলেন তাহলে আপনি আপনার ওয়ালেট এবং ব্যক্তিগত তথ্যের উপর অনেক নিয়ন্ত্রণ হারাতে পারেন, এবং তখন আপনি আপনার পক্ষে থেকে আইনত বাধ্যতামূলক ব্যবস্থা গ্রহণ করা হতে পারে।</p>

              <h4>অন্যান্য সাইটের লিংক</h4>
              <p>এই পরিষেবাতে অন্যান্য ওয়েবসাইটের লিংক পাওয়া যেতে পারে। যদি আপনি এটিতে (যেকোন লিংক) ক্লিক করেন তবে একটি তৃতীয় পক্ষের লিংক আপনাকে নির্দিষ্ট ওয়েবসাইটে পাঠাবে। দয়া করে এ ব্যাপারে সচেতন থাকুন যে আমরা এই বহিরাগত ওয়েবসাইটগুলি পরিচালনা করিনা। ফলস্বরূপ, ব্যবহার করার আগে, আমরা আপনাকে এই ওয়েবসাইটগুলির গোপনীয়তা নীতিগুলি অধ্যয়ন করার জন্য আন্তরিক পরামর্শ দিচ্ছি। আমরা আপনাকে জানাতে চাই যে কোনো তৃতীয় পক্ষের সাইট বা পরিষেবার বিষয়বস্তু, গোপনীয়তা নীতি, বা অনুশীলনের উপর আমাদের কোনো নিয়ন্ত্রণ নেই এবং কোনো দ্বায় স্বীকার করিনা।</p>

              <h4>অধিগ্রহণ বা একীভূতকরণ</h4>
              <p>যদি এসটি পে অন্য কোম্পানির সাথে একীভূত হয় বা অন্য কোম্পানি দ্বারা অধিগ্রহণ করা হয়, তাহলে উত্তরসূরি কোম্পানিটি এসটি পে দ্বারা আপনার তথ্যের রক্ষণাবেক্ষণ করার অ্যাক্সেস থাকবে।</p>

              <h4>শিশুদের গোপনীয়তা</h4>
              <p>১৩ বছরের কম বয়সী যে কেউ এই পরিষেবাগুলির জন্য অনুমতিপ্রাপ্ত নয়৷ ১৩ বছরের কম বয়সী শিশুরা সাধারনত আমাদের এই ব্যক্তিগত তথ্য সনাক্তকারী সংগ্রহের অধীন হয়না। যদি আমরা জানতে পারি যে ১৩ বছরের কম বয়সী কোন শিশু তাদের ব্যক্তিগত তথ্য এসটি পে-তে দিয়েছে, তাহলে আমরা আমাদের সার্ভার থেকে তা মুছে ফেলি। আমরা সদয়ভাবে অনুরোধ করছি যে আপনি যদি একজন পিতা বা মাতা বা অভিভাবক হন তবে আপনি আমাদের সাথে যোগাযোগ করুন এবং আবিষ্কার করুন যে আপনার সন্তান আমাদের ব্যক্তিগত তথ্য দিয়েছে যাতে আমরা যথাযথ ব্যবস্থা নিতে পারি।</p>
              <p>এই ওয়েবসাইটে শর্তাবলী আপডেট করে, আমরা যে কোনো মুহূর্তে এই গোপনীয়তা বিবৃতি পরিবর্তন করতে পারি। ওয়েবসাইটে তাদের প্রাথমিক পোস্ট করার পরে, সমস্ত পরিবর্তিত শর্তাবলী স্বয়ংক্রিয়ভাবে সরাসরি কার্যকর হবে। ফলস্বরূপ, আপনাকে পর্যায়ক্রমে আপডেটের জন্য এই পৃষ্ঠাটি পরীক্ষা করার পরামর্শ দেওয়া হচ্ছে। যে কোনো সময় আমরা একটি পরিবর্তন করি, আমরা আপনাকে জানাতে এই পৃষ্ঠায় এটি সম্পর্কে একটি বিজ্ঞপ্তি (বা একটি সংশোধিত নীতি) পোস্ট করব৷ এই সাইটে প্রকাশিত হওয়ার সাথে সাথে এই পরিবর্তনগুলি কার্যকর হবে৷</p>

            </Grid>
          </Grid>
        </Container>
      </div>
      <div className={classes.footer}>
        <FooterBangla />
      </div>
    </>
  );
};

export default PrivacyPolicyBangla;
