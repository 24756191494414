import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import Footer from "../components/Footer";
import HeaderOthers from "../components/HeaderOthers";

const useStyle = makeStyles((theme) => ({
    root: {
        margin: "60px 0px",
    },
    title: {
        fontSize: "1.5rem !important",
        fontWeight: "500 !important",
        marginBottom: "20px !important",
        textAlign: "center",
    },

    content: {
        fontSize: "1rem",
        marginBottom: "20px !important",
    },
    subtitle: {
        fontSize: "1rem",
        marginBottom: "20px !important",
        fontWeight: "500 !important",
    },

    icon: {
        marginRight: 10,
        position: "relative",
        top: 5,
    },
    select: {
        marginBottom: 30,
    },
    footer: {
        marginTop: "-80px",
    },
}));

const PrivacyPolicy = () => {
    const classes = useStyle();

    useEffect(() => {
        window.scrollTo(0, -1);
    }, []);


    return (
        <>
            <HeaderOthers title="Terms & Conditions of Partner Registration" />
            <div className={classes.root}>
                <Container maxWidth="lg">
                    <Grid container spacing={2}>
                        <Grid item md={12}>

                            {/* <Typography className={classes.title}>ST Pay Privacy Policy</Typography> */}

                            <p>This present agreement is established in order to set up a contractual relationship by and between: Share Trip Limited (hereinafter referred to as “ShareTrip”), a private limited company incorporated in Bangladesh under the respective Companies Act 1994; having office at JCX Business Tower, 1136/A, Level 8, Block: I, Bashundhara, Dhaka 1229 and operates an online marketplace.</p>

                            <p>AND</p>

                            <p>The Partner (hereinafter referred to as “Partner”), a sole proprietor/company, registered under the laws of the People’s Republic of Bangladesh. ShareTrip and Partner are hereinafter referred to collectively as the “Parties” and individually as a “Party”) This document is an electronic record in terms of Information Technology and Communication Act 2006 and the amended provisions pertaining to Information Technology and Communication Act of 2006. This electronic record is generated by a computer system and does not require any physical or digital signatures.</p>

                            <h4>1. GENERAL TERMS</h4>
                            <p>1.1.	ShareTrip is authorized to facilitate purchases on behalf of Partner. The scope of services provided by ShareTrip is restricted to customer referrals to Partner and the acceptance of orders and payments on Partner’s behalf. Upon receiving an order through ShareTrip's website and/or app Partner is promptly notified and the product or service is subsequently delivered to the customer directly by Partner.</p>

                            <p>1.2.	This agreement expressly supersedes prior agreements or arrangements between both Parties unless expressly agreed otherwise between the Parties.</p>

                            <p>1.3.	Both Parties agree that customer satisfaction is the ultimate interest responsible for guiding the commercial actions and behavior of both Parties.</p>

                            <p>1.4.	ShareTrip will provide Partner with analytics. Analytics may include customer’s name, contact number, shipping address, and other info to enhance sales.</p>

                            <p>1.5.	ShareTrip will sell the products at the regular retail price which are fixed by Partner; if there is any joint promotion between the parties approved by the ShareTrip management. ShareTrip will introduce such offer in its online store subject to mutual discussion. In various occasion and campaigns ShareTrip may be offering discounts to promote the sale of the Brand's products and Voucher, split of commission in which cases that also would be mutually agreed by the parties.</p>

                            <p>1.6.	Once ShareTrip confirms an order; Partner will be responsible for Delivery the product (s) and/or service (s) to customer from their warehouse or designated stores.  ShareTrip will not be liable for damage of products and delay or failure of delivery.</p>

                            <p>1.7.	In the event, that ShareTrip facilitate the delivery of product (s) and/or service (s) to the customer, either ShareTrip or its assigned delivery partner will arrange for the pickup of the product (s) and/or service (s) from their warehouse or designated stores.</p>

                            <p>1.8.	ShareTrip will provide a back end/dashboard (Partner Panel) to Partner so that, Partner can upload the details of each product (s) and/or service (s) such as images, type, color, material, price, ideal measurement, quantity available, etc.</p>

                            <p>1.9.	ShareTrip will promptly share real time information regarding sales order via email/SMS, Google sheet/Drop box, API as agreed upon by both parties.</p>

                            <p>1.10.	Every transaction of the Partner on ShareTrip’ platform is bounded to the acceptance of the terms of this agreement as well as the details, annexes, appendices, platform policies mentioned in this agreement and/or available on ShareTrip. This agreement will be considered valid as soon as it is electronically accepted by the Partner.</p>

                            <p>1.11.	This support is covered within the agreed level of commission and any additional service fees.</p>

                            <p>1.12.	ShareTrip may use the services of subcontractors to execute any part of the present agreement or any kind of future services made available to the Partner without any prior intimation.</p>

                            <p>1.13.	Any reference in this Agreement to any provision of a statute shall be construed as a reference to that provision as amended, re-enacted or extended at the relevant time.</p>

                            <p>1.14.	The platform policies are to be read into and incorporated as an integral part of this agreement. Due to constant improvement of ShareTrip’ services, platform policies will evolve and change over time (with notice to the Partner). The Partner’s use of the platform and access to the Partner Portal is subject to this agreement and the latest platform policies available on ShareTrip.</p>
                            <p>1.15.	In order to maintain its reputation for quality and high service standards, ShareTrip reserves the right to delist the Partner and to terminate the relationship with the Partner based on ShareTrip’ internal quality assessment of the Partner as governed by ShareTrip internal policy.</p>
                            <p>1.16.	After going through and successfully completing the Signup Process, ShareTrip shall provide the Partner with a unique username and password to access the Partner Portal and complete the registration process.</p>
                            <p>1.17.	The Partner is responsible for maintaining up to date information pertaining to their business on Partner Portal such as, but not limited to, address and bank account number. ShareTrip is not responsible for any liability arising from incorrect information supplied by the Partner.</p>
                            <p>1.18.	The Partner shall be solely responsible for the safety and security of its password and shall not disclose its password to any third party. The Partner is solely responsible for any use of or action taken under the password and shall fully indemnify ShareTrip from any damages or injury resulting from any unauthorized use of its password.</p>
                            <p>1.19.	Any correspondence or communication received through the Partner Portal and/or appointed email address shall be presumed to originate from and have been made with the approval of the Partner and ShareTrip shall be entitled to rely on such correspondence or communication.</p>


                            <h4>2.	INTERPRETATION</h4>
                            <h5>2.1	In these Conditions:</h5>
                            <ul>
                                <li>"Customer" refers to the individual purchasing Goods on the Platform.</li>

                                <li>“Partner” is a Partner utilizing the Platform and/or Services to sell Goods to Buyers, including Third-Party Vendors. ShareTrip may also act as a “Partner” for selected Goods.</li>

                                <li>“Third-Party Vendor” is a Partner using the Platform and/or Services to sell Goods to Buyers, excluding ShareTrip.</li>

                                <li>"Goods" (or "product") includes goods available for sale on the Platform, comprising any installment of the goods or any related parts.</li>

                                <li>“Services” encompass the use of any services, information, and functions provided by ShareTrip on the Platform.</li>

                                <li>“Bank Account”	The bank account specified by the Partner in which payments are to be made.</li>

                                <li>“Business Day”	A day (excluding Fridays And Saturdays) on which banks generally are open for business in Bangladesh.</li>

                                <li>“Commission Schedule”	The schedule setting out the commission which is payable to ShareTrip by the Partner for each type of Product sold on the Platform.</li>

                                <li>“Competitor” Any person or entity, that directly or indirectly, engages in the sale of Products on the internet in Bangladesh. For the avoidance of doubt, any person whose direct or indirect business is only partially similar to the aforementioned would still be deemed to be a Competitor.</li>

                                <li>“Contract” The contract entered into between the Partner and a Customer for the sale and purchase of the Products on the Platform.</li>

                                <li>“Dropship” “Fulfillment model whereby the Partner is responsible for maintaining inventory of Products at own premises and making available to ShareTrip for delivery to Customers.</li>

                                <li>“Fee(s)” Any fees charged by ShareTrip for any additional services such as pickup or returns.</li>
                                <li>“Final Delivery”	The transfer of ownership of the product from the Partner to the end customer.</li>
                                <li>“Fulfillment Center” A facility provided by ShareTrip where all ShareTrip Express Products are stored and where orders are processed.</li>
                                <li>“General Terms” The terms set out in this agreement.</li>
                                <li>“In writing/written” All communications made through the Partner Portal or sent by ShareTrip through courier or email.</li>
                                <li>“Intellectual Property” Any patent, copyright, registered or unregistered design, design right, registered or unregistered trademark, service mark or other industrial or intellectual property right and includes applications for any of them.</li>
                                <li>“Listed Price” The listing price of the Product on the Platform and shall be that price at which the Partner informs ShareTrip that it wishes to sell any Product and which price shall include any taxes, whether federal, provincial or local, which the Partner is required and liable to pay on the sale or supply of each Product or type of Product. The Listed Price shall not be higher than the price at which the Partner offers the same Product through its own sales channels.</li>
                                <li>“Penalty/Penalties” Any financial and/or operational penalty inflicted by ShareTrip on the Partner for any breach of Platform Policies</li>
                                <li>“Pending” The status on the Partner Portal depicting that an order has been received and awaiting processing.</li>
                                <li>“Pending” The status on the Partner Portal depicting that an order has been received and awaiting processing.</li>
                                <li>“Performance Scorecard” The report conveyed to the Partner by ShareTrip which depicts the operational performance of the Partner.</li>
                                <li>“Platform” The website ShareTrip or any affiliate website.</li>
                                <li>“Platform Policies” All the policies and guidelines applicable to Partners and available on ShareTrip.</li>
                                <li>“Product(s)” The products which the Partner intends to sell on the Platform.</li>
                                <li>“Rejected Product” A Product that has been shipped but could not be successfully delivered to a Customer, for any reason whatsoever.</li>
                                <li>“Required Product Information”	Means, with respect to each of the Products, the following (except to the extent expressly not required under the applicable Platform Policies):
                                    <ul>
                                        <ol>a)	detailed description, including as applicable, specifications, location-specific availability and options, scheduling guidelines and service cancellation policies; </ol>
                                        <ol>b)	Product numbers, and other identifying information as ShareTrip may reasonably request;</ol>
                                        <ol>c)	information regarding in-stock status and availability, shipping limitations or requirements, and shipment information; </ol>
                                        <ol>d)	categorization within each Product category and browse structure as prescribed by ShareTrip from time to time; </ol>
                                        <ol>e)	digitized image that accurately depicts the Product, complies with all ShareTrip image guidelines, and does not include any additional logos, text or other markings; </ol>
                                        <ol>f)	Listed Price; </ol>
                                        <ol>g)	any text, disclaimers, warnings, notices, labels or other content required by applicable law to be displayed in connection with the offer, merchandising, advertising or sale of the Product; </ol>
                                        <ol>h)	any Partner requirements, restocking fees or other terms and conditions applicable to such Product that a Customer should be aware of prior to purchasing the Product; </ol>
                                        <ol>i)	brand; </ol>
                                        <ol>j)	model; </ol>
                                        <ol></ol>
                                        <ol>k)	product dimensions; </ol>
                                        <ol>l)	weight; </ol>
                                        <ol>m)	a delimited list of technical specifications; </ol>
                                        <ol>n)	Product numbers (and other identifying information as ShareTrip may reasonably request) for accessories related to the Product that is available in ShareTrip’ catalog; and </ol>
                                        <ol>o)	any other information reasonably requested by ShareTrip (e.g., the condition of used or refurbished products)</ol>
                                    </ul>
                                </li>

                                <li>“Return Policy”	The policy governing the return, refund, cancellation or rejection of products and which can be found at ShareTrip Platform.</li>
                                <li>“Returned Product” A Product that had been delivered but has been returned by the Customer, for any reason whatsoever.</li>
                                <li>“Partner Support Center” The support service provided by ShareTrip to solve the issues faced by Partners as well as help Partners grow their business.</li>
                                <li>“Shipped” A Product is considered shipped and on course for delivery to the Customer.</li>
                                <li>“Shipped” A Product is considered shipped and on course for delivery to the Customer.</li>
                                <li>“Registration Process” Process via which person(s) or entities sign up to sell on ShareTrip’ online marketplace.</li>
                                <li>“Third Party Logistics (3PL)” An external service provider designated by ShareTrip for shipping.</li>
                            </ul>



                            <h4>3.	PARTNER OBLIGATIONS TO CUSTOMER SERVICE</h4>
                            <p>3.1.	ShareTrip may forward questions or complaints received by ShareTrip’ Customer Service department regarding any sold Product(s) to the Partner. The Partner shall be obliged to respond to ShareTrip on all such questions or complaints within forty-eight (48) hours of the receipt of such questions or complaints.</p>

                            <p>3.2.	On receiving Partner’s response to any question or complaint, ShareTrip shall promptly forward the response to the Customer.</p>
                            <p>3.3.	If a Partner fails to respond to any question or complaint within forty-eight (72) hours of receiving the same, the Partner or any of its Products may be de-listed from the Platform without further notice.</p>

                            <h4>4.	COMMISSION</h4>
                            <p>4.1.	ShareTrip shall be entitled to receive a commission for the sale of each Product on the Platform, as stipulated in the Commission Schedule unless specified otherwise in any special terms agreed upon.</p>
                            <p>4.2.	The commissions are calculated as a percentage of the Listed Price inclusive of all applicable taxes.</p>
                            <p>4.3.	These Commissions are deducted by ShareTrip when making a payout to the Partner defined under Payments.</p>
                            <p>4.4.	ShareTrip reserves the right to adjust the percentage Commission defined in the Commission schedule, providing suitable notice of 14 days in advance to the Partner before enactment.</p>

                            <h4 >5.	PAYMENTS</h4>
                            <p>5.1.	Customer has the option to utilize various payment methods available on the Platform. Actual payment occurs only when the Partner accepts the customer’s order, marking the formation of a Contract. All payments are directed to ShareTrip. The Customer acknowledges that ShareTrip is authorized to collect payments on behalf of the Partner(s).</p>
                            <p>5.2.	The payments will be made to the Partner on a weekly basis to the Bank Account, provided that payment related to any Product shall be initiated not less than 7 days after the Product has been successfully delivered to the Customer. </p>
                            <p>5.3. ShareTrip will ensure payment statements are available on the Partner Portal with all relevant payment information.</p>
                            <p>5.4.	All payments will be made in Bangladeshi Taka. In case the Partner wishes to change the information for the Bank Account, it may do so by updating its Bank Account information through the Partner Portal. Payments will be made via online transfer to the Bank Account specified by the Partner in the Partner Portal.</p>
                            <p>5.5.	The Partner agrees that ShareTrip shall not be liable for any failure to make payments arising due to incomplete or inaccurate information provided by the Partner with regards to its Bank Account</p>
                            <p>5.6.	In case a payment has been issued by ShareTrip to the Partner for a delivered item that is later returned to the partner by the customer, then subject to partner request ShareTrip will refund the amount to the customer and ShareTrip will deduct equivalent amount in the next cycle and adjust the amount from Partner payable amount.</p>
                            <p>5.7.	ShareTrip shall be entitled to deduct or withhold from payments to be made to the Partner under this agreement any duties, taxes or other amounts required to be deducted or withheld under any federal, provincial or local law and to remit the same to the taxing authority of any jurisdiction relevant to the transaction.</p>
                            <p>5.8.	Any sums due to the Partner hereunder may be applied by ShareTrip as a set off against any sums owed by the Partner to ShareTrip, or against any claims of third parties against ShareTrip arising from the Partner’ performance, whether under this agreement, any Contract or other document.</p>
                            <p>5.9.	The Partner shall be responsible for payment of all customs duties, sales tax, excise tax, value-added tax and any other duties, excess, fees or charges of whatsoever nature which may be imposed by governing authorities of any jurisdiction applicable in connection with sale or supply of its Products on the Platform and their purchase by Customers. It is clarified that the Partner shall be solely responsible for the payment of any sales tax on the supply of these Products to the Customer through the Platform and ShareTrip shall have no liability in this regard.</p>
                            <p>5.10.	ShareTrip shall receive and process all payments for Products purchased on the Platform on behalf of the Partner for a service fee. ShareTrip shall periodically remit to the Partner a payment equivalent to the amount of the Listed Price for the Product(s) received from a Customer, less any promotional discounts/bundles or offers as provided to the customer by the Partner, less ShareTrip's commission for the sale of the Product(s), less any other service Fees/Penalties or any other amounts due if applicable, subject to its right of set-off under this agreement.</p>
                            <p></p>

                            <h4>6. WARRANTIES AND REMEDIES</h4>


                            <h4>Website Traffic and Web Catches:</h4>
                            <p>6.1.	No condition is made or to be implied nor is any warranty given or to be implied as to the life or wear of the Goods supplied or that they will be suitable for any particular purpose or use under any specific conditions, notwithstanding that such purpose or conditions may be known or made known to ShareTrip. </p>
                            <p>6.2.	Any description given of the Goods is given by way of identification only and the use of such description shall not constitute a sale by description. </p>
                            <p>6.3.	ShareTrip binds itself only to deliver Goods in accordance with the general description under which they were sold, whether or not any special or particular description shall have been given or shall be implied by law. Any such special or particular description shall be taken only as the expression of ShareTrip's opinion in that behalf. ShareTrip is not liable for any such special or particular description which may have been provided by Third-Party Vendors through. ShareTrip does not give any warranty as to the quality state condition or fitness of the Goods.</p>
                            <p>6.4.	ShareTrip shall be under no liability for the following measures and actions taken by the Buyer or third parties and the consequences thereof: improper remedy of defects, alteration of the Goods without the prior agreement of ShareTrip, addition and insertion of parts, in particular of spare parts which do not come from ShareTrip. </p>
                            <p>6.5.	ShareTrip shall be under no liability in respect of any defect arising from unsuitable or improper use, defective installation or commissioning by the Buyer or third parties, fair wear and tear, willful damage, negligence, abnormal working conditions, defective or negligent handling, improper maintenance, excessive load, unsuitable operating materials and replacement materials, poor work, unsuitable foundation, chemical, electro-technical/electronic or electric influences, failure to follow ShareTrip's instructions (whether oral or in writing) misuse or alteration or repair of the Goods without ShareTrip's approval. </p>
                            <p>6.6.	ShareTrip is not liable for any loss, damage or liability of any kind suffered by any third party directly or indirectly caused by repairs or remedial work carried out without ShareTrip’s prior written approval and the Buyer shall indemnify ShareTrip against each loss, liability and cost arising out of such claims. </p>

                            <p>6.7.	ShareTrip shall be under no liability under the above warranty (or any other warranty condition or guarantee) if the total price for the Goods has not been paid in cleared funds by the due date for payment.</p>

                            <p>6.8.	ShareTrip shall be under no liability whatsoever in respect of any defect in the Goods arising after the expiry of the Warranty Period.</p>

                            <h4>7.	 LIABILITY</h4>
                            <p>7.1.	As a selling channel ShareTrip is not liable for any returns and all return matters shall be directed to Partner.</p>
                            <p>In no event shall ShareTrip be liable for loss of profit or goodwill, loss of production or revenue or any type of special indirect or consequential loss whatsoever (including loss or damage suffered by the Buyer as a result of an action brought by a third party) even if such loss were reasonably foreseeable or if ShareTrip had been advised by the Buyer of the possibility of incurring the same. </p>
                            <p>Notwithstanding any other provision of these Conditions, the Partner’s maximum cumulative liability to a Buyer or to any other party for all losses under, arising out of or relating to the sale of Goods under each Contract, shall not exceed the sums that the Buyer paid to the Partner under such Contract. </p>
                            <p>7.4.	No action shall be brought against ShareTrip later than 3 months after the date a Buyer became aware of the circumstances giving rise to a claim or the date when he/ she / it ought reasonably to have become aware, and in any event, no later than 3 months after the end of the Warranty Period.</p>

                            <h4>8.	FORCE MAJEURE</h4>
                            <p>8.1.	ShareTrip shall not be liable to the Partner or be deemed to be in breach of this agreement by reason of any delay in performing or any failure to perform any of ShareTrip’ obligations if the delay or failure was due to any cause beyond ShareTrip’ reasonable control. Without prejudice to the generality of the foregoing the following shall be regarded as causes beyond ShareTrip’ reasonable control:</p>
                            <p>8.2.	Act of God, explosion, flood, tempest, fire or accident war or threat of war, sabotage, insurrection, civil disturbance or requisition;</p>
                            <p>8.3.	Acts, restrictions, regulations, bye-laws, prohibitions or measures of any kind on the part of any governmental, parliamentary or local authority;</p>
                            <p>8.4.	Import or export regulations or embargoes;</p>
                            <p>8.5.	Interruption of traffic, strikes lock-outs or other industrial actions or trade disputes (whether involving employees of ShareTrip or of a third party);</p>
                            <p>8.6.	Interruption of production or operation, difficulties in obtaining raw materials labor, fuel, parts or machinery;</p>
                            <p>8.7.	Power failure or breakdown in machinery.</p>
                            <p>8.8.	ShareTrip may, at its option, fully or partially suspend delivery/performance while such circumstances continue and ShareTrip shall not be liable for any loss or damage suffered by the Partner as a result of such suspension, including but not limited to from the Partner’s failure to fulfill any Contract with a Customer.</p>

                            <h4>9.	ASSIGNMENT</h4>
                            <p>9.1.	The Partner may not assign this agreement or any Contract, or any part hereof, or any money due hereunder, without the prior written consent of ShareTrip. If consent is granted, any such assignment by the Partner shall not increase or alter ShareTrip’ obligations nor diminish the rights of ShareTrip, nor relieve the Partner of any of its obligations under this agreement or any Contract.</p>
                            <p>9.2.	ShareTrip reserves the right to assign this agreement, in whole or in part, to any party, including ShareTrip’ affiliates.</p>
                            <p>9.3.	The Partners shall give ShareTrip prompt written notice of any change in its ownership or organization, and changes in the manufacture or production of the Products provided hereunder.</p>

                            
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div className={classes.footer}>
                <Footer />
            </div>
        </>
    );
};

export default PrivacyPolicy;
