import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyle = makeStyles((theme) => ({
    root: {
        margin: "40px 0px",
    },
    title: {
        fontSize: "1.5rem !important",
        fontWeight: "500 !important",
        marginBottom: "20px !important",
        textAlign: "center",
    },
}));

const Konnect = () => {
    const classes = useStyle();
    return (
        <div className={classes.root}>
            <Typography className={classes.title}>
                Terms & Conditions for KONNECT
            </Typography>

            <p>
                1. ST Pay Users/Customers are deemed to have read, understood, and accepted the terms and
                conditions regulating the registration and use of the "KONNECT" online payment service. <br />

                2. Brand name “ST Pay” means both “HomePay Limited” and “Green and Red
                Technologies Limited (G&R) companies. They may update these terms with immediate
                effect by setting them out here. They also reserve the right to change the fees at any time
                without giving any prior notice. “Konnect” will complete the payment procedures, after
                confirmation by the client. <br />

                3. The customers’ payment will normally reach ST Pay account to which are making a
                payment through “Konnect” within 48 (Forty-Eight) hours of working days. <br />

                4. “ST Pay” will not be liable for a transfer/payment not reaching the ST Pay account for any
                inaccuracies and any other reasons. <br />

                5. “ST Pay” will not incur any liability if payment is refused or declined by the credit/debit
                card supplier.<br />

                6. “ST Pay” will not be liable for any damages whatsoever arising out of the use /inability
                to use / the results of use of this site/ 5possibility of such damages. <br />

                7. “ST Pay” shall not be held responsible in any way or pay any compensation for any
                down-time. <br />

                8. “ST Pay” is not responsible for incorrect or inaccurate entry information, whether caused
                by user(s), “Konnect”, or by any technical or human error. <br />

                9. “ST Pay” shall not be held responsible for damages resulting from any of the foregoing to
                the fullest extent permitted under applicable laws. <br />

                10. “ST Pay” will maintain the confidentiality of each transaction and will not disclose the
                commercially sensitive information, except to the extent necessary for the Services. <br />

                11. Cancellation or refund policy are approved on case to case basis at the discretion of the
                “ST Pay” management. <br />

                12. This Privacy Policy applies to all fee payments listed by (G&R). Sometimes, we may post
                fee specific privacy notices or Help Centre materials to explain the fee structure in more
                detail. <br />

                13. ST Pay reserves the entire right to modify/amend/remove this privacy statement anytime
                and without giving any prior notice. <br />

                14. Nothing contained herein creates or is intended to create a contract/agreement between
                Green and Red Technologies Limited (G&R) and any user visiting ST Pay Application or
                providing identifying information of any kind. <br />

                15. The customers are responsible for ensuring the accuracy of this information, and are
                responsible for maintaining the safety and security of identifying information of the
                customers. The customers are also responsible for all activities that occur under their
                account or password. <br />

                16. We reserve all rights to terminate accounts, edit or remove content and cancel services
                provided by the company mentioned in the Application at our sole discretion. <br />

                17. There may be information on our site or in the Service that contains typographical errors,
                inaccuracies or omissions that may relate to package descriptions, pricing, promotions,
                offers and availability and We reserve the right to correct any errors, inaccuracies or
                omissions, and to change or update information if any information in the Service or on any
                related Application is inaccurate at any time without prior notice. <br />

                18. These Terms of Use and any disputes arising out of or related to the Site shall be governed
                by and construed and enforced in accordance with, the laws of People's Republic of
                Bangladesh. Any dispute, controversy or claim arising out of this or the breach, termination
                or invalidity thereof, shall be settled by arbitration in accordance with the laws of
                Bangladesh. <br />

                19. Any cause of action or claim, must be commenced within 60 days otherwise such cause of
                action or claim is barred. <br />

                20. The trademarks, trade dress, logos, and service marks displayed on this Site are owned by
                Green and Red Technologies Limited or third parties. Nothing contained on this Site may
                be construed as granting by implication, estoppel, or otherwise, any right or license to use
                any trademark. <br />

                21. The customers are prohibited from using the site or its content in any manner which is
                banned, illegal, or immoral. We reserve the right to terminate the customer’s use of the
                Service for any of the prohibited uses. <br />

                22. Any dispute in relation to payments shall be placed within 48 (Forty-Eight) hours from
                when the cause of action arises otherwise such claim would be barred by limitation and
                no such claim shall be entertained by the company. <br />

                23. Customer Care Number: +880 9617343434
            </p>
        </div>
    );
};

export default Konnect;