import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import React, { useEffect } from 'react';


import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { useLocation } from "react-router-dom";


const useStyle = makeStyles((theme) => ({
    root: {
        margin: "60px 0px",
    },
    title: {
        fontSize: "1.5rem !important",
        fontWeight: "500 !important",
        marginBottom: "20px !important",
    },
    content: {
        fontSize: "1rem",
        marginBottom: "20px !important",
    },
    subtitle: {
        fontSize: "1rem",
        marginBottom: "20px !important",
        fontWeight: "500 !important",
    },

    icon: {
        marginRight: 10,
        position: "relative",
        top: 5,
    },
    select: {
        marginBottom: 30,
    },
    footer: {
        marginTop: "-80px",
    },
}));

const OffersFaqBangla = () => {
    const classes = useStyle();

    const location = useLocation();
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
        // let path = "/faq";
        // let pathName = location.pathname;
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography>ক্যাশব্যাক কত পেতে পারি?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body2">
                        Total cashback is BDT 500. Per transaction cashback limit is BDT 250
                        with a minimum transaction of BDT 5,000.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography>আমরা কিভাবে ৫০০ টাকা ক্যাশব্যাক পেতে পারি?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body2">
                        By making payment (top-up) to ShareTrip B2B panel through ST Pay.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography>আমি কতবার ক্যাশব্যাক পাব?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body2">
                        In one account you will get a maximum of BDT 500 cashback. Per
                        transaction maximum cashback is BDT 250 with a minimum transaction
                        of BDT 5,000
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion
                expanded={expanded === "panel4"}
                onChange={handleChange("panel4")}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography>
                        ShareTrip B2B প্যানেলে টপ-আপ করার সময় কি কোনো চার্জ আছে?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body2">
                        ShareTrip will not charge anything on Top-up.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion
                expanded={expanded === "panel55"}
                onChange={handleChange("panel55")}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography>
                        ShareTrip B2B প্যানেলে টপ-আপ করার সময় কি কোনো চার্জ আছে?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body2">
                        Interestingly there is no charges on send money, mobile recharge and
                        other services of ST Pay During the campaign period.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion
                expanded={expanded === "panel5"}
                onChange={handleChange("panel5")}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography>আমি কতবার পেমেন্ট দিতে পারব?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body2">
                        Maximum 100 times we make payment to merchants per day and 500 times
                        per month.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion
                expanded={expanded === "panel6"}
                onChange={handleChange("panel6")}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography>আমি কত টাকা দিতে সক্ষম হবো?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body2">
                        Using one ST Pay account we can pau up to BDT 400,000 per day and per
                        month.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion
                expanded={expanded === "panel7"}
                onChange={handleChange("panel7")}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography>
                        এই অফারটি কতদিন চলবে এবং কখন আমরা ক্যাশব্যাক পাব?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body2">
                        It’s a 1 month campaign so this offer is valid through out June’22
                        and the cashback is instant.
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </>
    );
};

export default OffersFaqBangla;