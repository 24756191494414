import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader


// import banner_nagad from "../../assets/images/banner-image/banner-nagad.png";
// import banner_upay from "./../../assets/images/banner-image/banner-upay.png";
import banner_st_pay from "./../../assets/images/banner-image/banner-st-pay3.png";

// Download Button
import download_from_app_store from "./../../assets/images/download-from-app-store.png";
import download_from_google_store from "./../../assets/images/download-from-google-store.png";


class Slider extends React.Component {


	render() {
		return (
			<>
				<div id="download-div" className='absolute z-50 m-4'>
					<a href="https://play.google.com/store/apps/details?id=com.deshi.personal" target="_blank" rel="noreferrer">
						<img src={download_from_google_store} alt="Download from Google Store"></img>
					</a>
					<a href="https://apps.apple.com/gb/app/deshi-spend-save-invest/id1643458886" className='ml-2 sm:w-[50px]' target="_blank" rel="noreferrer">
						<img src={download_from_app_store} alt="Download from App Store"></img>
					</a>
				</div>
				<Carousel autoPlay axis="horizontal" swipeable={true} infiniteLoop interval={3000}
					showStatus={false} showIndicators={false} showThumbs={false} emulateTouch={true} showArrows={false}>
					<div>
						<img src={banner_st_pay} alt="ST Pay" />
					</div>
					{/* <div>
						<img src={banner_upay} alt="Add money from Upay" />
					</div> */}
				</Carousel>

				<style>{`
					@media only screen and (max-width: 750px) {
						#download-div {
							margin: 5px !important;
						}

						#download-div img {
							width: 70px !important;
							height: 20px !important;
						}
					}
				`}</style>

			</>
		);
	}
}


export default Slider;
