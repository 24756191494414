import { Typography } from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useEffect } from 'react';
import offer_banner from '../../../assets/images/offer/banner/conv-fee-0.jpeg';
import circle from "../../../assets/images/circle4.png";
import offer_bottom from "../../../assets/images/offer_bottom.png";
import FooterBangla from '../../../components/componentsBangla/FooterBangla';
import HeaderOthersBangla from '../../../components/componentsBangla/HeaderOthersBangla';

const useStyle = makeStyles((theme) => ({
  root: {
    margin: "60px 0px",
    [theme.breakpoints.down("sm")]: {
      margin: "0px",
    },
  },
  title: {
    fontWeight: "500 !important",
    marginBottom: "20px !important",
    marginTop: "24px !important",
  },
  subTitle: {
    fontWeight: "500 !important",
    marginBottom: "20px !important",
    marginTop: "0px !important",
  },
  content: {
    fontSize: "1rem",
    marginBottom: "20px !important",
  },

  img: {
    maxWidth: "100%",
  },
  footer: {
    marginTop: "-80px",
  },
  card: {
    padding: "24px",
    boxShadow: "1px 1px 35px 1px #eee",
    borderRadius: "10px",
    marginTop: "32px",
    [theme.breakpoints.down("sm")]: {
      padding: "7px",
    },
  },
  ul: {
    margin: 0,
    paddingLeft: "0px !important",
  },
  li: {
    marginBottom: "16px",
    listStyle: "none",
    display: "flex",
    alignItems: "flex-start",
  },
  circle: {
    marginRight: "10px",
    display: "flex",
    position: "relative",
    top: 5,
  },
  offer_bottom: {
    backgroundImage: `url(${offer_bottom}),  linear-gradient(to  right,  #FCFCFC, #FAFAFA)`,
    height: "430px",
    // py={6} pl={16} pr={2}
    paddingTop: 48,
    paddingBottom: 84,
    paddingLeft: 128,
    paddingRight: 16,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 30,
    },
  },
  offer_bottom2: {
    position: "relative",
    top: -60,
    right: -50,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const ConveFee0Bangla = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const classes = useStyle();
  return (
    <>
      <HeaderOthersBangla title="asdasd" category="Offers" />
      <div className={classes.root}>
      <Container maxWidth="lg">
          <Grid container spacing={2}>
            <Grid item md={12} style={{ width: "100%" }}>
              <Box className={classes.card}>
                <img src={offer_banner} alt="কনভিনিয়ান্স ফি চার্জ ০%" className={classes.img} />
                <Typography variant="h3" className={classes.title}>
                  কনভিনিয়ান্স ফি চার্জ ০%? 
                </Typography>
                <p>
                ঠিক তাই! শেয়ারট্রিপ-এ ST Pay-এর মাধ্যমে  পেমেন্ট করে পছন্দের যেকোনো সার্ভিস বুক করলেই কনভিনিয়েন্স ফি চার্জ ০%। আজই শেয়ারট্রিপ অ্যাপে আপনার ST Pay একাউন্ট রেজিস্টার করে উপভোগ করুন দারুণ অফারটি ।<br/>
                ক্লিক করুন  <a className="text-[#1882ff]" href="https://stl.travel/st" target="_blank" rel="noreferrer">https://stl.travel/st</a>
                </p>
                <p className="pt-4">
                  <strong>অফারটি চলবে <br/></strong>
                  ৩১শে ডিসেম্বর ২০২৩ পর্যন্ত
                </p>

                <p className="pt-4">
                  <strong>বিস্তারিত জানতে<br/></strong>  
                  📞 কল করুন  : <a className="font-semibold" href="tel:+8809617617617">+880 961 761 7617</a> (সকাল ৯:০০ টা - রাত ১:০০ টা)<br/>
                  📧 ইমেইল করুন  : <a className="font-semibold" href="mailto:ask@sharetrip.net">ask@sharetrip.net</a><br/>
                  💬 মেসেজ দিন : <a className="font-semibold" href="https://m.me/sharetrip.net">https://m.me/sharetrip.net</a>
                </p>
                
              </Box>
              <Box className={classes.card}>
                <Typography variant="h4" className={classes.subTitle}>
                বিস্তারিত
                </Typography>

                <ul className={classes.ul}>
                  <li className={classes.li}>
                    <img src={circle} alt="" className={classes.circle} />
                    <span>
                      আপনি শুধুমাত্র এই ক্যাশব্যাকের জন্য যোগ্য হবেন যদি আপনি ST Pay ওয়ালেট ব্যবহার করে অর্থ প্রদান করেন।
                    </span>
                  </li>

                  <li className={classes.li}>
                    <img src={circle} alt="" className={classes.circle} />
                    <span>
                    আপনি যখন বণিক QR কোড বা অনলাইন গেটওয়ের মাধ্যমে অর্থ প্রদানের জন্য আপনার ST Pay অ্যাকাউন্ট ব্যবহার করেন, তখন আপনি এই অফারটির জন্য যোগ্য হবেন।
                    </span>
                  </li>

                </ul>
                <ul className={classes.ul}>
                  
                  <li className={classes.li}>
                    <img src={circle} alt="" className={classes.circle} />
                    <span>
                      This offer valid until 31<sup>st</sup> December, 2023. (*শর্ত প্রযোজ্য)
                    </span>
                  </li>

                  <li className={classes.li}>
                    <img src={circle} alt="" className={classes.circle} />
                    <span>
                      "ShareTrip" is entirely responsible for the information,
                      products, and services related to this Campaign. Under
                      this promotion, ST Pay will only be responsible for the
                      cashback as a payment service provider.
                    </span>
                  </li>

                  <li className={classes.li}>
                    <img src={circle} alt="" className={classes.circle} />
                    <span>
                      ST Pay reserves the right to change, modify, or extend
                      these terms and conditions, as well as to cancel this
                      campaign, at any time and without warning.
                    </span>
                  </li>
                  <li className={classes.li}>
                    <img src={circle} alt="" className={classes.circle} />
                    <span>
                      Any decision made by ST Pay regarding this campaign will be
                      made in ST Pay's sole discretion and will be considered
                      final.
                    </span>
                  </li>
                </ul>

                <p className="pt-3">
                  For more details <a className="text-[#1882ff]" href="https://sharetrip.net/" >click here</a>.
                </p>
              </Box>

              <Box className={classes.card} mb={8}>
                <Typography variant="h4" className={classes.subTitle}>
                  Terms and Conditions
                </Typography>
                <Typography variant="body1" mb={3}>
                  ST Pay announces that:
                </Typography>

                <ul className={classes.ul}>
                  <li className={classes.li}>
                    <img src={circle} alt="" className={classes.circle} />
                    <span>
                      ST Pay will never ask you to reveal your ST Pay account's
                      One-time Password (OTP) or Personal Identification Number
                      (PIN), nor will they ask you to make any payment to
                      anyone.
                    </span>
                  </li>

                  <li className={classes.li}>
                    <img src={circle} alt="" className={classes.circle} />
                    <span>
                      In the event of a dispute, you will initially contact
                      ShareTrip during the campaign period at the following
                      numbers: +8809617617617.
                    </span>
                  </li>

                  <li className={classes.li}>
                    <img src={circle} alt="" className={classes.circle} />
                    <span>
                      It shall contact customers using only +8809617617617. For
                      any confusion or conflict with regards to this campaign,
                      you must end any other call and immediately call back to
                      +8809617617617 to ascertain the authenticity of the call
                      or for required information.
                    </span>
                  </li>
                  <li className={classes.li}>
                    <img src={circle} alt="" className={classes.circle} />
                    <span>
                      ST Pay will not be liable for any loss or damage resulting
                      from any of the aforementioned reasons or any other
                      third-party acts.
                    </span>
                  </li>
                  <li className={classes.li}>
                    <img src={circle} alt="" className={classes.circle} />
                    <span>
                      In the event of a conflict between the English version and
                      the Bangla version of the terms and conditions, the
                      English version shall prevail.
                    </span>
                  </li>
                </ul>
              </Box>
              
            </Grid>
          </Grid>
        </Container>
      </div>
      <div className={classes.footer}>
        <FooterBangla />
      </div>
    </>
  );
};

export default ConveFee0Bangla;