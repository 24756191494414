import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyle = makeStyles((theme) => ({
    root: {
        margin: "40px 0px",
    },
    title: {
        fontSize: "1.5rem !important",
        fontWeight: "500 !important",
        marginBottom: "20px !important",
        textAlign: "center",
    },
}));

const CCBillPayAndTransferMoneyBangla = () => {
    const classes = useStyle();
    return (
        <div className={classes.root}>
            <Typography className={classes.title}>
                Terms & Conditions for <br/>
                “Credit Card Bill Pay & Transfer Money”
            </Typography>

            <ol >
                <li>ST PAY customers are eligible to pay Visa/Mastercard credit card bills issued by any commercial bank in Bangladesh through their ST PAY account.</li>
                <li>Each ST PAY customer can avail this service multiple times by paying Visa/Mastercard credit card bills in BDT through the ST PAY App, subject to the applicable local (Bangladesh) credit limit.</li>
                <li>Although, there is no limit to the credit card bill payments, ST PAY customer must adhere to the applicable payment rules of the respective bank.</li>
                <li>To be eligible for this service, ST PAY customers must maintain an active ST PAY account with a 'Full Profile' status and sufficient balance.</li>
                <li>A ST PAY customer will be charged of applicable 1.5% on the amount of the bill (on payment amount).</li>
                <li>ST PAY reserves the right to modify, amend, or cancel these terms and conditions or the service itself at any time without prior notice.</li>
                <li>Visa/Mastercard will bear sole liability for their credit card bill payment service, with ST PAY assuming responsibility solely as a 
                payment service provider</li>
                <li>Any decisions made by ST PAY regarding this service will be at the sole discretion of ST PAY and considered final.</li>
                
                <br/>

                <li style={{marginTop: "30px"}}>
                    <b style={{color: "rgb(0 56 151)"}}>ST PAY announces that:</b> 
                    <br/>

                    <ol type="a">
                        <li>
                        The customers' One-time Password (OTP) or Personal Identification Number
                    (PIN) for their ST PAY account or VISA/MASTERCARD credit card cannot be
                    requested or asked by ST PAY or its authorized agent at any time.
                        </li>

                        <li>The ST PAY Customer Service Representative may note the last 4 digits of the
                        credit card number on the ticketing system, email, or paper (if required). However,
                        customers should be informed that the full card number cannot be disclosed under
                        any circumstances.</li>

                        <li>The ST PAY Customer Service Representative shall not ask the customer to make
                    any payment to anyone whatsoever. If the customer falls victim to any kind of
                    fraud, ST PAY will not be liable. The customer is solely responsible for any
                    transactions made from their ST PAY account.</li>
                        <li>ST PAY shall contact the customer using only 096 173 43434. If the customer
                    encounters any confusion or conflict regarding this service, they must end any
                    other call and immediately call back to 096 173 43434 to verify the authenticity of
                    the call or obtain the required information.</li>
                        <li>The bill will be submitted to the bank's credit card management system for
                    processing, and the payment status will be updated within 3 working days. If the
                    Corporate Office
                    +880 9617343434 JCX Business Tower (6th Floor) www.stpay.net
                    1136/A, Block I, Bashundhara R/A, Dhaka 1229
                    customer incurs a late fee due to making a last-minute credit card payment via ST
                    PAY, they should directly contact the card-issuing bank to address and resolve the
                    issue.</li>
                        <li>Customers are unable to use the ST PAY app to check out their credit card bills.
                    The ST PAY service is only available for Visa/Mastercard credit card bill
                    payments. If a payment is made to the incorrect card number, the customer needs
                    to reach the bank that issued the credit card to resolve the issue. Similarly, if the
                    payment amount is incorrect at the time of payment, the customer needs to contact
                    the bank that issued the credit card (For example, planning to pay 1000 Taka but
                    ending up paying 10,000 Taka).</li>
                    <li>Due to the aforementioned reasons, as well as any additional activities by a third
                    party, ST PAY disclaims all liability for any loss or damages. Customers should
                    initially contact ST PAY at 096 173 43434, the ST PAY hotline numbers, in the
                    event of any disputes.</li>

                    </ol>
                    

                </li>

            </ol>


            <Typography className={classes.title} style={{marginTop: "80px"}}>
                Terms & Conditions for <br/>
                “ST PAY to Visa Debit Card Transfer Money”
            </Typography>

            <ol >
                <li>ST PAY customers are eligible to transfer money from his/her ST PAY account to a
Visa Debit Card issued by any commercial bank in Bangladesh.</li>
<li>Customers must enter their Debit Card information accurately to transfer money from
his/her ST PAY account to a Visa Debit Card(s).</li>
<li>ST PAY shall not be liable if the transfer of money fails or the money is transferred to
an unintended card due to incorrect information provided by the customer.</li>
<li>ST PAY to Visa Debit Card transfer money limit is cumulatively merged with ‘Send
Money’ limit.</li>
<li>To be eligible for this service, an ST PAY customer must have an active ST PAY
account with a 'Full Profile' status and sufficient balance.</li>
<li>A ST PAY customer shall be charged 1.5% on the transfer amount as applicable.</li>
<li>ST PAY shall not assume liability for any rejected 'Transfer Money' due to customer
mistakes or non-compliance with rules.</li>
<li>ST PAY shall not be held responsible for any delays by the concerned bank in crediting
the customer's selected card.</li>
<li>VISA will bear sole liability for its debit card fund transfer service. ST PAY will only
be responsible as a payment service provider.</li>
<li>ST PAY reserves the right to alter, modify, or cancel these terms and conditions or the
service itself at any time without prior notice. Any decision taken by ST PAY with
respect to this service, shall be in the sole discretion of ST PAY and shall be deemed
final.</li>
            

                <br/>

                <li style={{marginTop: "30px"}}>
                    <b style={{color: "rgb(0 56 151)"}}>ST PAY announces that:</b> 
                    <br/>

                    <ol type="a">
                        <li>
                        The customers' One-time Password (OTP) or Personal Identification Number
(PIN) for their ST PAY account or VISA Debit card cannot be requested or asked
by ST PAY or its authorized agent at any time.
                        </li>
                        <li>The ST PAY Customer Service Representative may note the last 4 digits of the
credit card number on the ticketing system, email, or paper (if required). However,
customers should be informed that the full card number cannot be disclosed under
any circumstances.</li>
                        <li>The ST PAY Customer Service Representative shall not ask the customer to make
any payment to anyone whatsoever. If the customer falls victim to any kind of
fraud, ST PAY will not be liable. The customer is solely responsible for any
transactions made from their ST PAY account.</li>
                        <li>ST PAY shall contact the customer using only 096 173 43434. If the customer
encounters any confusion or conflict regarding this service, they must end any
other call and immediately call back to 096 173 43434 to verify the authenticity of
the call or obtain the required information.</li>
                        <li>If a payment is made to the incorrect card number, the customer needs to reach the
bank that issued the debit card to resolve the issue. Similarly, if the payment
amount is incorrect at the time of payment, the customer needs to contact the bank
that issued the debit card (For example, planning to pay 1000 Taka but ending up
paying 10,000 Taka).</li>
                        <li>Due to the aforementioned reasons, as well as any additional activities by a third
party, ST PAY disclaims all liability for any loss or damages. Customers should
initially contact ST PAY at 096 173 43434, the ST PAY hotline numbers, in the
event of any disputes.</li>


                    </ol>
                    

                </li>

            </ol>


            <style>{`
                ol {
                    margin-left: -10px;
                    text-align: justify;
                }

                ol li::marker {
                    font-weight: 600;
                }

                ol li {
                    padding-left: 15px;
                    margin-bottom: 10px;
                }
            `}</style>

        </div>
    );
};

export default CCBillPayAndTransferMoneyBangla;